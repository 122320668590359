import React, { useState } from "react";
import * as ItemServices from "../../../../services/ItemService";
import PaginateList from "../../../utils/PaginateList";
import { useTranslation } from "react-i18next";
import {
  checkBootstrapSizeBreak,
  SIZES,
} from "../../../../utils/device/checkDevice";
import { ALMOND_TYPE } from "../../../../constants/index";

const ItemsListContainer = ({ filters, sortBy, errors }) => {
  const { t } = useTranslation();
  const deviceSize = checkBootstrapSizeBreak();
  const [forceRefresh, setForceRefresh] = useState(false);

  const columns = [
    {
      header: t("forms.items.table.head.code"),
      accessorKey: "code",
      cell: (info) => info.getValue(),
      size: 50,
    },
    {
      header: t("forms.items.table.head.variety"),
      accessorKey: "variety",
      cell: (info) => info.getValue(),
      size: 100,
    },
    {
      header: t("forms.items.table.head.name"),
      accessorKey: "name",
      cell: (info) => info.getValue(),
      size: 200,
    },
    {
      header: t("forms.items.table.head.calibre"),
      accessorKey: "calibre",
      size: 100,
      cell: (info) => {
        if (info.row.original.calibre === "NO SEL") {
          return "-";
        }
        return info.getValue();
      },
    },
    {
      header: t("forms.items.table.head.type"),
      accessorKey: "type",
      size: 100,
      cell: (info) => {
        if (info.row.original.type) {
          const type = ALMOND_TYPE.find(
            (st) => st.code === info.row.original.type
          );
          return type ? t(type.label) : info.getValue();
        }
        return "-";
      },
    },
  ];

  const listData = async (filter) => {
    if (sortBy) {
      filter.sort = sortBy;
    } else {
      filter.sort = "-code";
    }
    const result = await ItemServices.listFilter(filter);
    return result;
  };

  const hideColumn = {};
  let resize = null;

  // if (deviceSize < SIZES.md) {
  //   hideColumn.itemCode = false;
  //   resize = false;
  // } else if (deviceSize === SIZES.md) {
  //   hideColumn.itemCode = false;
  // }

  return (
    <>
      <PaginateList
        refresh={forceRefresh}
        filters={filters}
        columns={columns}
        toList={listData}
        hideColum={hideColumn}
        resize={resize}
        resetRefresh={() => setForceRefresh(false)}
      ></PaginateList>
    </>
  );
};

export default ItemsListContainer;
