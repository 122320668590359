import React, { useState } from "react";
import * as AuxItemService from "../../../../services/AuxItemService";
import PaginateList from "../../../utils/PaginateList";
import { useTranslation } from "react-i18next";
import {
  checkBootstrapSizeBreak,
  SIZES,
} from "../../../../utils/device/checkDevice";
import { AUX_TYPE } from "../../../../constants/index";
import { getDateFormatTimeZone } from "../../../../utils/formats";

const AuxItemsListContainer = ({ filters, sortBy, errors }) => {
  const { t } = useTranslation();
  const deviceSize = checkBootstrapSizeBreak();
  const [forceRefresh, setForceRefresh] = useState(false);

  const columns = [
    {
      header: t("forms.auxItems.table.head.date"),
      accessorKey: "createdAt",
      cell: (info) =>
        getDateFormatTimeZone(info.getValue(), "DD-MM-yyyy HH:mm"),
    },
    {
      header: t("forms.auxItems.table.head.type"),
      accessorKey: "type",
      size: 100,
      cell: (info) => {
        if (info.row.original.type) {
          const type = AUX_TYPE.find(
            (st) => st.code === info.row.original.type
          );
          return type ? t(type.label) : info.getValue();
        }
        return "-";
      },
    },
    {
      header: t("forms.auxItems.table.head.batch"),
      accessorKey: "batch",
      cell: (info) => info.getValue(),
    },
    {
      header: t("forms.auxItems.table.head.code"),
      accessorKey: "code",
      cell: (info) => info.getValue(),
    },
    {
      header: t("forms.auxItems.table.head.amount"),
      accessorKey: "quantity",
      // cell: (info) => info.getValue(),
      cell: (info) => {
        const { original } = info.row;
        return `${original.quantity}/${
          original.quantity - original.currentQuantity
        }`;
      },
    },
    {
      header: t("forms.auxItems.table.head.status"),
      accessorKey: "active",
      cell: (info) => {
        if (info.row.original.active) {
          return t("enable");
        }
        return t("disable");
      },
    },
  ];

  const listData = async (filter) => {
    if (sortBy) {
      filter.sort = sortBy;
    } else {
      filter.sort = "-code";
    }
    const result = await AuxItemService.listFilter(filter);
    return result;
  };

  const hideColumn = {};
  let resize = null;

  // if (deviceSize < SIZES.md) {
  //   hideColumn.itemCode = false;
  //   resize = false;
  // } else if (deviceSize === SIZES.md) {
  //   hideColumn.itemCode = false;
  // }

  return (
    <>
      <PaginateList
        refresh={forceRefresh}
        filters={filters}
        columns={columns}
        toList={listData}
        hideColum={hideColumn}
        resize={resize}
        resetRefresh={() => setForceRefresh(false)}
      ></PaginateList>
    </>
  );
};

export default AuxItemsListContainer;
