import React, { useState } from "react";
import List from "../outProcessList";
import Filter from "../outFilter";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import PATHS from "../../../../constants/paths";
import Breadcrumb from "../../../utils/breadCrumbs";
import { Can, AbilityContext } from "../../../security/permissions/Can";
import { USER_PERMISSIONS } from "../../../security/permissions/rolesPermission";
import moment from "moment-timezone";
import * as XLSX from "xlsx";
import * as OrderService from "../../../../services/OrderServices";
import { getDateTimeZone } from "../../../../utils/formats";

const queryClient = new QueryClient();

const OutFilterListContainer = (props) => {
  const { t } = useTranslation();
  const breads = [
    { label: t("home"), path: PATHS.HOME },
    { label: t("forms.out.title") },
  ];

  const [filters, setFilters] = useState(undefined);
  const navigate = useNavigate();

  const handlerCleanFilter = async (resetForm) => {
    resetForm();
    setFilters(undefined);
  };

  const handlerOnSubmitFilter = (values) => {
    let newFilter = {};
    const valuesFilters = Object.entries(values);
    valuesFilters.forEach((value) => {
      if (value[1] !== undefined && value[1].toString().length > 0) {
        newFilter[value[0]] = value[1];
      }
    });

    setFilters(newFilter);
  };

  const exportToExcel = async () => {
    const filter = {
      filters,
      sort: "-date",
    };
    OrderService.listToExport(filter).then((result) => {
      const data = result.map((out) => {
        // const type = ALMOND_TYPE.find((st) => st.code === out.type);
        return {
          A: getDateTimeZone(out.date),
          B: t(out.point),
          C: out.item,
          D: out.lote,
          E: out.weight,
          F: out.batchBB,
          G: out.batchTag,
        };
      });
      data.unshift({
        A: "Fecha",
        B: "Punto E/S",
        C: "Artículo",
        D: "Lote",
        E: "Peso (Kg)",
        F: "Lote Bigbag",
        G: "Lote Etiquetas",
      });

      const filename = `Salidas_export_${moment(new Date()).format(
        "DDMMyyyyHHmm"
      )}`;
      const sheetName = "Listado de salidas";
      var ws = XLSX.utils.json_to_sheet(data, {
        header: ["A", "B", "C", "D", "E", "F", "G"],
        skipHeader: true,
        UTC: true,
        dateNF: "dd-mm-yyyy hh:mm;@",
      });
      // dateNF:'mm/dd/yyyy;@',cellDates:true, raw: true}
      const wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, sheetName);
      XLSX.writeFile(wb, filename + ".xlsx");
    });
  };

  return (
    <>
      <div className="row justify-content-center mt-5">
        <div className="col-12 col-md-11 marg-btm-1rem marg-top-40px">
          <h3 className="title">{t("forms.out.title")}</h3>
        </div>
      </div>
      <Breadcrumb items={breads} />
      <div className="row justify-content-center">
        <div className="col-12 col-md-11">
          <Filter
            onSubmit={handlerOnSubmitFilter}
            onClean={handlerCleanFilter}
          />
        </div>
      </div>
      <div className="row justify-content-center">
        <div className="col-12 col-md-11">
          <QueryClientProvider client={queryClient}>
            <List className="marg-top-1rem" filters={filters} />
          </QueryClientProvider>
        </div>
      </div>
      <Can do={USER_PERMISSIONS.EXPORT_MOVE}>
        <div className="row justify-content-center mt-4 mb-5">
          <div className="col-12 col-md-11 text-center">
            <button
              type="button"
              className="btn btn-lg btn-secondary"
              onClick={() => exportToExcel()}
            >
              <i className="fa-solid fa-file-excel icon-button"></i>{" "}
              {t("exportFileExcel")}
            </button>
          </div>
        </div>
      </Can>
    </>
  );
};

OutFilterListContainer.propTypes = {};

export default OutFilterListContainer;
