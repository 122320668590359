import React, { useState, useContext } from "react";
import * as ExtControlServices from "../../../../services/ExtControlServices";
import PaginateList from "../../../utils/PaginateList";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import {
  checkBootstrapSizeBreak,
  SIZES,
} from "../../../../utils/device/checkDevice";
import { ALMOND_TYPE } from "../../../../constants/index";
import { ButtonAction, Icon, ButtonClose } from "./extControlList.styled";
import { useDispatch } from "react-redux";
import { show, hide } from "redux-modal";
import showDialog from "../../../utils/ShowDialog/ShowDialog";
import { Can, AbilityContext } from "../../../security/permissions/Can";
import { USER_PERMISSIONS } from "../../../security/permissions/rolesPermission";
import { getDateFormatTimeZone } from "../../../../utils/formats";

const ExtControlListContainer = ({ filters, sortBy, errors }) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const deviceSize = checkBootstrapSizeBreak();
  const [forceRefresh, setForceRefresh] = useState(false);
  const dispatch = useDispatch();
  const context = useContext(AbilityContext);

  const handlerClosePopup = (popupName) => {
    dispatch(hide(popupName));
  };

  const columns = [
    {
      header: t("forms.extControl.table.head.date"),
      accessorKey: "date",
      cell: (info) => getDateFormatTimeZone(info.getValue(), "DD-MM-yyyy"),
      size: 200,
    },
    {
      header: t("forms.extControl.table.head.time"),
      accessorKey: "time",
      // cell: (info) => info.getValue(),
      cell: (info) => getDateFormatTimeZone(info.row.original.date, "HH:mm"),
    },
    {
      header: t("forms.extControl.table.head.variety"),
      accessorKey: "variety",
      cell: (info) => info.getValue(),
    },
    {
      header: t("forms.extControl.table.head.batch"),
      accessorKey: "batch",
      cell: (info) => info.getValue(),
    },
    {
      header: t("forms.extControl.table.head.silo"),
      accessorKey: "silo",
      cell: (info) =>
        info.getValue().replace("MOJADO", "").replace("MOLHADO", ""),
    },
    {
      header: t("forms.extControl.table.head.humFactoryIn"),
      accessorKey: "humFactoryIn",
      cell: (info) => (info.getValue() ? info.getValue() : "-"),
    },
    {
      header: t("forms.extControl.table.head.humPartidoraIn"),
      accessorKey: "humPartidoraIn",
      cell: (info) => (info.getValue() ? info.getValue() : "-"),
    },
    {
      header: t("forms.extControl.table.head.humSmartOut"),
      accessorKey: "humSmartOut",
      cell: (info) => (info.getValue() ? info.getValue() : "-"),
    },
    {
      header: t("forms.extControl.table.head.humSecOut"),
      accessorKey: "humSecOut",
      cell: (info) => (info.getValue() ? info.getValue() : "-"),
    },
    {
      header: "",
      accessorKey: "ac",
      cell: (info) => renderAction(info.row.original),
    },
  ];

  const goToEdit = (uuid) => {
    navigate(`/factory/ext-control/update/${uuid}`);
  };

  const renderAction = (data) => {
    return (
      <Can do={USER_PERMISSIONS.EDIT_QUALITY}>
        <div className="dropdown text-center">
          <ButtonAction
            onClick={() => goToEdit(data.uuid)}
            title={t("forms.order.labels.edit")}
          >
            <Icon className="fa-regular fa-pen-to-square"></Icon>
          </ButtonAction>
        </div>
      </Can>
    );
  };

  const listData = async (filter) => {
    const result = await ExtControlServices.listFilter(filter);
    return result;
  };

  const hideColumn = {};
  let resize = null;

  if (deviceSize < SIZES.md) {
    // hideColumn.itemCode = false;
    resize = false;
  } else if (deviceSize === SIZES.md) {
    // hideColumn.itemCode = false;
  }

  return (
    <PaginateList
      refresh={forceRefresh}
      filters={filters}
      columns={columns}
      toList={listData}
      hideColum={hideColumn}
      resize={resize}
      resetRefresh={() => setForceRefresh(false)}
    ></PaginateList>
  );
};

export default ExtControlListContainer;
