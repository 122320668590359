import React, { useState } from "react";
import List from "../auxItemsList";
// import Filter from "../auxItemsFilter";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { useTranslation } from "react-i18next";
import PATHS from "../../../../constants/paths";
import Breadcrumb from "../../../utils/breadCrumbs";
import { useNavigate } from "react-router-dom";

// import * as ItemService from "../../../../services/ItemService";
// import * as XLSX from "xlsx";
// import { ALMOND_TYPE } from "../../../../constants/index";

const queryClient = new QueryClient();

const AuxItemsFilterListContainer = (props) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const breads = [
    { label: t("home"), path: PATHS.HOME },
    { label: t("forms.auxItems.title") },
  ];
  const [filters, setFilters] = useState(undefined);

  // const handlerCleanFilter = async (resetForm) => {
  //   resetForm();
  //   setFilters(undefined);
  // };

  // const handlerOnSubmitFilter = (values) => {
  //   let newFilter = {};
  //   const valuesFilters = Object.entries(values);
  //   valuesFilters.forEach((value) => {
  //     if (value[1] !== undefined && value[1].toString().length > 0) {
  //       newFilter[value[0]] = value[1];
  //     }
  //   });

  //   setFilters(newFilter);
  // };

  const gotoRegisterNewItem = () => {
    navigate(PATHS.NEW_AUX_ITEMS);
  };

  return (
    <>
      <div className="row justify-content-center mt-5">
        <div className="col-12 col-md-11 marg-btm-1rem marg-top-40px">
          <h3 className="title">{t("forms.auxItems.title")}</h3>
        </div>
      </div>
      <Breadcrumb items={breads} />
      {/* <div className="row justify-content-center">
        <div className="col-12 col-md-11">
          <Filter
            onSubmit={handlerOnSubmitFilter}
            onClean={handlerCleanFilter}
          />
        </div>
      </div> */}
      <div className="row justify-content-center">
        <div className="col-12 col-md-11">
          <QueryClientProvider client={queryClient}>
            <List className="marg-top-1rem" filters={filters} />
          </QueryClientProvider>
        </div>
      </div>
      <div className="row justify-content-center mt-4 mb-5">
        <div className="col-12 col-md-11 text-center">
          <button
            type="button"
            className="btn btn-lg btn-secondary"
            onClick={() => gotoRegisterNewItem()}
          >
            {t("forms.auxItems.buttons.new")}
          </button>
        </div>
      </div>
    </>
  );
};

AuxItemsFilterListContainer.propTypes = {};

export default AuxItemsFilterListContainer;
