import React, { useState, useEffect } from "react";
import List from "../turnHistoryListCalendar/turnHistoryListCalendarClose.container";
// import Filter from "../turnFilter";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import PATHS from "../../../../constants/paths";
import Breadcrumb from "../../../utils/breadCrumbs";
import { useTurn } from "../hook/useTurn";
import { useDispatch } from "react-redux";
import { show, hide } from "redux-modal";
import {
  ResumeContainer,
  MinutesContainer,
  Divider,
  Resume,
  NpContainer,
  CaContainer,
  PContainer,
} from "./styled";
import { useStopAndPlay } from "../../stopHistory/hook/useStopAndPlay";
import * as TurnServices from "../../../../services/TurnServices";
import { getDateFormatTimeZone } from "../../../../utils/formats";

const queryClient = new QueryClient();

function convertirMilisegundos(ms) {
  // 1 segundo = 1000 milisegundos
  // 1 minuto = 60 segundos = 60 * 1000 milisegundos
  // 1 hora = 60 minutos = 60 * 60 * 1000 milisegundos
  let horas = Math.floor(ms / (1000 * 60 * 60)); // Convertir milisegundos a horas
  let minutos = Math.round((ms % (1000 * 60 * 60)) / (1000 * 60)); // Calcular minutos restantes

  if (minutos === 60) {
    horas = horas + 1;
    minutos = 0;
  }
  return { h: horas, m: minutos };
}

const CloseTurnContainer = (props) => {
  const { t } = useTranslation();
  const [refresh, setRefresh] = useState(false);
  const [weight, setWeight] = useState(0);
  const [comment, setComment] = useState("");
  const [data, setData] = useState(null);
  const { lastTurn, closeTurn } = useTurn();
  const dispatch = useDispatch();
  const refreshData = () => {
    setRefresh(true);
  };

  const { customStop } = useStopAndPlay(refreshData);

  useEffect(() => {
    if (!data || refresh) {
      TurnServices.resume({
        dateInit: new Date(),
        dateEnd: new Date(),
      }).then((result) => {
        setData(result);
      });
    }
  }, [refresh]);

  useEffect(() => {
    setRefresh(false);
  }, [refresh]);

  const breads = [
    { label: t("home"), path: PATHS.HOME },
    { label: t("forms.turnHistory.title") },
    { label: t("forms.turnHistory.closeTurnTitle") },
  ];
  const [filters, setFilters] = useState(undefined);
  const navigate = useNavigate();

  const handlerClosePopup = (popupName) => {
    dispatch(hide(popupName));
  };

  let prev = 0;
  let camb = 0;
  let aver = 0;
  let totp = 0;
  let totprod = 0;
  let total = 0;

  if (data) {
    prev = convertirMilisegundos(data?.totalClean);
    camb = convertirMilisegundos(data?.totalChange);
    aver = convertirMilisegundos(data?.totalOther);

    totp = convertirMilisegundos(
      data?.totalClean + data?.totalChange + data?.totalOther
    );
    totprod = convertirMilisegundos(
      data?.totalTime -
        (data?.totalClean + data?.totalChange + data?.totalOther)
    );

    total = convertirMilisegundos(
      data?.totalClean +
        data?.totalChange +
        data?.totalOther +
        (data?.totalTime -
          (data?.totalClean + data?.totalChange + data?.totalOther))
    );
  }

  const handlerOnchangeWeight = (value) => {
    setWeight(Number(value));
  };

  const handlerOnchangeComment = (value) => {
    setComment(value);
  };
  return (
    <>
      <div className="row justify-content-center mt-5">
        <div className="col-12 col-md-11 marg-btm-1rem marg-top-40px">
          <h3 className="title">{t("forms.turnHistory.closeTurnTitle")}</h3>
        </div>
      </div>
      <Breadcrumb items={breads} />
      <div className="row justify-content-center">
        <div className="col-12 col-md-11"></div>
      </div>
      <div className="row justify-content-center mt-4">
        <div className="col-12 col-md-6">
          <div className="row mb-3">
            <div className="col-12">
              <Resume>{t("forms.turnHistory.resume")}</Resume>
            </div>
          </div>
          <div className="row mb-3">
            <div className="col-12">
              <ResumeContainer>
                <div className="row">
                  <div className="col-12">
                    <strong>
                      {`${t("forms.turnHistory.labels.day")}: `}
                      {data?.init
                        ? `
                        ${getDateFormatTimeZone(data.init, "DD-MM-yyyy")}`
                        : ""}
                    </strong>
                  </div>
                </div>
                <div className="row">
                  <div className="col-12">
                    <strong>
                      {`${t("forms.turnHistory.labels.turn")}: `}
                      {data?.init
                        ? `${getDateFormatTimeZone(
                            data.init,
                            "HH:mm"
                          )} - ${getDateFormatTimeZone(data.end, "HH:mm")}`
                        : ""}
                    </strong>
                  </div>
                </div>
                <PContainer className="row mt-4">
                  <div className="col-7">
                    {t("forms.turnHistory.labels.stop01")}:
                  </div>
                  <div className="col-5 d-flex justify-content-end">
                    <div>{`${prev.h} ${t(
                      "forms.turnHistory.labels.hours"
                    )}`}</div>
                    <MinutesContainer>{`${prev.m} ${t(
                      "forms.turnHistory.labels.minutes"
                    )}`}</MinutesContainer>
                  </div>
                </PContainer>
                <CaContainer className="row">
                  <div className="col-7">
                    {t("forms.turnHistory.labels.stop02")}:
                  </div>
                  <div className="col-5 d-flex justify-content-end">
                    <div>{`${camb.h} ${t(
                      "forms.turnHistory.labels.hours"
                    )}`}</div>
                    <MinutesContainer>{`${camb.m} ${t(
                      "forms.turnHistory.labels.minutes"
                    )}`}</MinutesContainer>
                  </div>
                </CaContainer>
                <NpContainer className="row">
                  <div className="col-7">
                    {t("forms.turnHistory.labels.stop03")}:
                  </div>
                  <div className="col-5 d-flex justify-content-end">
                    <div>{`${aver.h} ${t(
                      "forms.turnHistory.labels.hours"
                    )}`}</div>
                    <MinutesContainer>{`${aver.m} ${t(
                      "forms.turnHistory.labels.minutes"
                    )}`}</MinutesContainer>
                  </div>
                </NpContainer>
                <Divider></Divider>
                <div className="row">
                  <div className="col-7">
                    {t("forms.turnHistory.labels.stop04")}:
                  </div>
                  <div className="col-5 d-flex justify-content-end">
                    <div>{`${totp.h} ${t(
                      "forms.turnHistory.labels.hours"
                    )}`}</div>
                    <MinutesContainer>{`${totp.m} ${t(
                      "forms.turnHistory.labels.minutes"
                    )}`}</MinutesContainer>
                  </div>
                </div>
                <div className="row">
                  <div className="col-7">
                    {t("forms.turnHistory.labels.stop05")}:
                  </div>
                  <div className="col-5 d-flex justify-content-end">
                    <div>{`${totprod.h} ${t(
                      "forms.turnHistory.labels.hours"
                    )}`}</div>
                    <MinutesContainer>{`${totprod.m} ${t(
                      "forms.turnHistory.labels.minutes"
                    )}`}</MinutesContainer>
                  </div>
                </div>
                <Divider></Divider>
                <div className="row">
                  <div className="col-7">
                    {t("forms.turnHistory.labels.stop06")}:
                  </div>
                  <div className="col-5 d-flex justify-content-end">
                    <div>{`${total.h} ${t(
                      "forms.turnHistory.labels.hours"
                    )}`}</div>
                    <MinutesContainer>{`${total.m} ${t(
                      "forms.turnHistory.labels.minutes"
                    )}`}</MinutesContainer>
                  </div>
                </div>
              </ResumeContainer>
            </div>
            <div className="row mt-4">
              <div className="col-12">
                <label>{t("forms.turnHistory.labels.displayKg")}</label>
              </div>
            </div>
            <div className="row">
              <div className="col-12">
                <input
                  type="number"
                  className="form-control"
                  defaultValue={weight}
                  onChange={(e) => handlerOnchangeWeight(e.target.value)}
                ></input>
              </div>
            </div>
            <div className="row mt-4">
              <div className="col-12">
                <label>{t("forms.turnHistory.labels.comment")}</label>
              </div>
            </div>
            <div className="row">
              <div className="col-12">
                <textarea
                  className="form-control"
                  defaultValue={comment}
                  onChange={(e) => handlerOnchangeComment(e.target.value)}
                ></textarea>
              </div>
            </div>
          </div>
          <div className="row mb-3 mt-5">
            <div className="col-12 text-center">
              <button
                className="btn btn-primary btn-md me-2"
                type="button"
                onClick={() => customStop()}
              >
                <span>{t("actions.newCustomstop")}</span>
              </button>
              <button
                className="btn btn-secondary btn-md"
                type="button"
                onClick={() => closeTurn({ weight, comment })}
              >
                <span>{t("forms.turnHistory.buttons.close")}</span>
              </button>
            </div>
          </div>
        </div>
        <div className="col-12 col-md-5">
          <div className="row mb-3">
            <div className="col-12 text-end"></div>
          </div>
          <div className="row">
            <div className="col-12">
              <QueryClientProvider client={queryClient}>
                <List
                  className="marg-top-1rem"
                  filters={{ init: data?.init, end: data?.end }}
                  refresh={refresh}
                />
              </QueryClientProvider>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

CloseTurnContainer.propTypes = {};

export default CloseTurnContainer;
