import moment from "moment-timezone";

const TIME_ZONE = "Europe/Lisbon";
export const converToTime = (milisegundos) => {
  // Calcula el número de horas
  const horas = Math.floor(milisegundos / 3600000);
  // Calcula el número de minutos restantes
  const minutosRestantes = Math.floor((milisegundos % 3600000) / 60000);
  // Calcula el número de segundos restantes
  const segundosRestantes = Math.floor(
    ((milisegundos % 3600000) % 60000) / 1000
  );

  const tiempoFormateado =
    pad(horas, 2) +
    ":" +
    pad(minutosRestantes, 2) +
    ":" +
    pad(segundosRestantes, 2);

  return tiempoFormateado;
};

const pad = (num, size) => {
  var s = num + "";
  while (s.length < size) s = "0" + s;
  return s;
};

export const getDateFormatTimeZone = (date, format) => {
  return moment(date).tz(TIME_ZONE).format(format);
};

export const getDateTimeZone = (date) => {
  return new Date(moment(date).tz(TIME_ZONE).format("yyyy-MM-DD HH:mm"));
};
