import React, { useContext } from "react";
import { useTranslation } from "react-i18next";
import LastProcess from "../dashboard/lastProcess";
import Actions from "../dashboard/actions";
import { checkBootstrapSizeBreak } from "../../../utils/device/checkDevice";
import { TitleContainer, LastProcessContainer } from "./home.style";
import { Can, AbilityContext } from "../../security/permissions/Can";
import { USER_PERMISSIONS } from "../../security/permissions/rolesPermission";

const HomeView = (props) => {
  const { t } = useTranslation();
  const deviceSize = checkBootstrapSizeBreak();
  const context = useContext(AbilityContext);

  return (
    <>
      <div className="row mt-4">
        <Can do={USER_PERMISSIONS.ADD_OUTPUT}>
          <div className="col-12 col-md-12 col-lg-3">
            <div className="row">
              <div className="col-12">
                <TitleContainer>{t("actionsTitle")}</TitleContainer>
                <LastProcessContainer $devicesize={deviceSize}>
                  <Actions></Actions>
                </LastProcessContainer>
              </div>
            </div>
            {/* <div className="row">
                    <div className="col-12 mt-3">
                      <TitleContainer>
                        {t("forms.dashboard.ranks")}
                      </TitleContainer>
                      <MedalContainer></MedalContainer>
                    </div>
                  </div> */}
            {/* <div className="row">
                    <div className="col-12 mt-3">
                      <TitleContainer>
                        {t("forms.dashboard.wallets")}
                      </TitleContainer>
                      <MyWalletContainer>
                        
                      </MyWalletContainer>
                    </div>
                  </div> */}
          </div>
        </Can>
        <div className="col-12 col-md-12 col-lg-6 mt-4 mt-md-3 mt-lg-0 mb-2">
          <div className="row">
            <div className="col-12">
              <TitleContainer>{t("forms.process.lastProcess")}</TitleContainer>
              <LastProcessContainer $devicesize={deviceSize}>
                <LastProcess></LastProcess>
              </LastProcessContainer>
            </div>
          </div>
          {/* <div className="row">
                    <div className="col-12 col-md-6 mt-3 mt-md-0">
                      <div className="row">
                        <div className="col-12">
                          <TitleContainer>
                            {t("forms.dashboard.qualify")}
                          </TitleContainer>
                          <MyQualifyContainer>
                          </MyQualifyContainer>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-12 mt-3">
                          <TitleContainer>
                            {t("forms.dashboard.commission")}
                          </TitleContainer>
                          <MyWalletContainer>
                          </MyWalletContainer>
                        </div>
                      </div>
                    </div> 
                    <div className="col-12 col-md-6 mt-3 mt-md-0">
                      <TitleContainer>
                        {t("forms.dashboard.volume")}
                      </TitleContainer>
                      <UserContainer>
                      </UserContainer>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-12 mt-3">
                      <TitleContainer>
                        {t("forms.dashboard.promotor")}
                      </TitleContainer>
                      <UserContainer>
                      </UserContainer>
                    </div>
                  </div>*/}
        </div>
      </div>
    </>
  );
};
export default HomeView;
