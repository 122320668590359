import React, { useState, useContext, useEffect, useCallback } from "react";
import List from "../extControlList";
import Filter from "../extControlFilter";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { useTranslation } from "react-i18next";
import PATHS from "../../../../constants/paths";
import Breadcrumb from "../../../utils/breadCrumbs";
import * as XLSX from "xlsx";
import * as ExtControlServices from "../../../../services/ExtControlServices";
import moment from "moment-timezone";
import { ALMOND_TYPE } from "../../../../constants/index";
import { Can, AbilityContext } from "../../../security/permissions/Can";
import { USER_PERMISSIONS } from "../../../security/permissions/rolesPermission";
import { useNavigate } from "react-router-dom";
import * as ItemService from "../../../../services/ItemService";
import { getDateFormatTimeZone } from "../../../../utils/formats";

const queryClient = new QueryClient();

const ExtControlFilterListContainer = (props) => {
  const { t } = useTranslation();
  const [varieties, setVarieties] = useState([]);
  const [filters, setFilters] = useState(undefined);
  const context = useContext(AbilityContext);
  const navigate = useNavigate();

  const breads = [
    { label: t("home"), path: PATHS.HOME },
    { label: t("forms.extControl.title") },
  ];

  const loadData = useCallback(async () => {
    const resultItems = await ItemService.getVarietiesCascara();
    if (resultItems && resultItems.length > 0) {
      const dataVarieties = resultItems.map((item) => {
        return {
          code: item.code,
          label: item.name,
          varietyCode: item.varietyCode,
          variety: item.variety,
        };
      });
      setVarieties(dataVarieties);
    }
  }, []);

  useEffect(() => {
    loadData();
  }, []);

  const handlerCleanFilter = async (resetForm) => {
    resetForm();
    setFilters(undefined);
  };

  const handlerOnSubmitFilter = (values) => {
    let newFilter = {};
    const valuesFilters = Object.entries(values);
    valuesFilters.forEach((value) => {
      if (value[1] !== undefined && value[1].toString().length > 0) {
        newFilter[value[0]] = value[1];
      }
    });

    setFilters(newFilter);
  };

  const goToForm = async () => {
    navigate(PATHS.NEW_EXT_CONTROL);
  };

  const exportToExcel = async () => {
    const filter = {
      filters,
      sort: "-date",
    };
    ExtControlServices.listToExport(filter).then((result) => {
      const data = result.map((ext) => {
        return {
          A: getDateFormatTimeZone(new Date(ext.date), "DD-MM-yyyy"),
          B: getDateFormatTimeZone(new Date(ext.date), "HH:mm"),
          C: ext.variety,
          D: ext.batch,
          E: ext.silo.replace("MOJADO", "").replace("MOLHADO", ""),
          F: ext.humFactoryIn || "-",
          G: ext.humPartidoraIn || "-",
          H: ext.humSmartOut || "-",
          I: ext.humSecOut || "-",
        };
      });
      data.unshift({
        A: "Fecha",
        B: "Hora",
        C: "Variedad",
        D: "Lote",
        E: "Silo",
        F: "Hum. Silo Kern (%)",
        G: "Hum. Entrada Partidora Kern (%)",
        H: "Hum. Entrada Sec. Kern (%)",
        I: "Hum. Salida Sec. Kern (%)",
      });

      const filename = `Control_export_${moment(new Date()).format(
        "DDMMyyyyHHmm"
      )}`;
      const sheetName = "Listado de controles";
      var ws = XLSX.utils.json_to_sheet(data, {
        header: ["A", "B", "C", "D", "E", "F", "G", "H", "I"],
        skipHeader: true,
        UTC: true,
        dateNF: "dd-mm-yyyy hh:mm;@",
      });
      // dateNF:'mm/dd/yyyy;@',cellDates:true, raw: true}
      const wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, sheetName);
      XLSX.writeFile(wb, filename + ".xlsx");
    });
  };

  return (
    <>
      <div className="row justify-content-center mt-5">
        <div className="col-12 col-md-11 marg-btm-1rem marg-top-40px">
          <h3 className="title">{t("forms.extControl.title")}</h3>
        </div>
      </div>
      <Breadcrumb items={breads} />
      <div className="row justify-content-center">
        <div className="col-12 col-md-11">
          <Filter
            onSubmit={handlerOnSubmitFilter}
            onClean={handlerCleanFilter}
            varieties={varieties}
          />
        </div>
      </div>
      <div className="row justify-content-center no-margin">
        <div className="col-12 col-md-11 mt-3 text-end">
          <button
            type="button"
            className="btn btn-md btn-secondary"
            onClick={() => exportToExcel()}
          >
            <i className="fa-solid fa-file-excel icon-button"></i>{" "}
            {t("exportFileExcel")}
          </button>
        </div>
      </div>
      <div className="row justify-content-center">
        <div className="col-12 col-md-11">
          <QueryClientProvider client={queryClient}>
            <List className="marg-top-1rem" filters={filters} />
          </QueryClientProvider>
        </div>
      </div>
      <Can do={USER_PERMISSIONS.REGISTER_QUALITY}>
        <div className="row justify-content-center mt-4">
          <div className="col-12 col-md-11 d-flex justify-content-center">
            <button
              className="btn btn-secondary btn-lg"
              onClick={() => goToForm()}
            >
              {t("forms.extControl.buttons.new")}
            </button>
          </div>
        </div>
      </Can>
    </>
  );
};

ExtControlFilterListContainer.propTypes = {};

export default ExtControlFilterListContainer;
