import React, { useState, useMemo, useEffect } from "react";
import * as TurnServices from "../../../../services/TurnServices";
import * as StopServices from "../../../../services/StopServices";
import moment from "moment-timezone";
import { useTranslation } from "react-i18next";
import {
  CalendarContainer,
  ButtonClose,
  TitleComment,
  Value,
  RadioLabel,
} from "./turnHistoryListCalendar.styled";
import {
  Calendar,
  Views,
  DateLocalizer,
  momentLocalizer,
} from "react-big-calendar";
import PropTypes from "prop-types";
import "react-big-calendar/lib/css/react-big-calendar.css";
import {
  CALENDAR_LANG,
  CATEGORY_HOLIDAY,
  EVENT_STYLE,
} from "../../../../constants/index";
import { useDispatch } from "react-redux";
import { show, hide } from "redux-modal";
import {
  CHANGES_CAUSES,
  CATEGORY_CLEAN,
  CATEGORY_CHANGES,
  CATEGORY_OTHERS,
  OTHER_CAUSES,
  CLEAN_CAUSES,
  MACHINES,
} from "../../../../constants/index";
import { RadioGroup, Radio } from "react-radio-group";
import "moment/locale/es";
import "moment/locale/pt";
import { getDateTimeZone } from "../../../../utils/formats";

const mLocalizer = momentLocalizer(moment);

const TurnHistoryListContainer = ({
  localizer = mLocalizer,
  refresh,
  defaultView,
  customViews,
  allowAll,
  showSelectType,
}) => {
  const { t, i18n } = useTranslation();
  const [dates, setDates] = useState(null);
  const [turns, setTurns] = useState([]);
  const [events, setEvents] = useState([]);
  const [eventType, setEventType] = useState(showSelectType ? "stops" : "all");
  const dispatch = useDispatch();

  const handlerClosePopup = (popupName) => {
    dispatch(hide(popupName));
  };

  const loadData = (init, end) => {
    setDates({ init, end });
    if (eventType === "turns" || eventType === "all") {
      TurnServices.listTurnEvent({
        dateInit: init,
        dateEnd: end,
        allowAll,
      }).then((result) => {
        if (eventType === "turns") {
          setTurns([]);
          setEvents(
            result.map((event) => {
              event.start = getDateTimeZone(event.start);
              event.end = getDateTimeZone(event.end);
              return event;
            })
          );
        } else {
          setTurns(result);
        }
      });
    }
    if (eventType === "stops" || eventType === "all") {
      StopServices.listEvent({
        dateInit: init,
        dateEnd: end,
      }).then((result) => {
        if (result && result.length > 0) {
          setEvents(
            result.map((event) => {
              event.title = t(event.title);
              event.start = getDateTimeZone(event.start);
              event.end = getDateTimeZone(event.end);
              return event;
            })
          );
        }
      });
    }
  };

  useEffect(() => {
    if (refresh) {
      loadData(
        moment(new Date()).startOf("month"),
        moment(new Date()).endOf("month")
      );
    }
  }, [refresh]);

  useEffect(() => {
    loadData(
      moment(new Date()).startOf("month"),
      moment(new Date()).endOf("month")
    );
  }, []);

  useEffect(() => {
    if (eventType) {
      loadData(
        moment(dates ? dates.init : new Date()).startOf("month"),
        moment(dates ? dates.end : new Date()).endOf("month")
      );
    }
  }, [eventType]);

  const { defaultDate, max, views, messages, eventStyleGetter } = useMemo(
    () => ({
      defaultDate: new Date(),
      // max: dates.add(dates.endOf(new Date(2015, 17, 1), "day"), -1, "hours"),
      views: customViews || {
        month: true,
        week: true,
        day: true,
      },
      messages: CALENDAR_LANG[i18n.language],
      eventStyleGetter: (myEventsList) => {
        // const backgroundColor = "#fe0fe0";
        const backgroundColor = myEventsList.type
          ? EVENT_STYLE[myEventsList.type].background
          : "#fe0fe0";
        // const color = "#fff";
        const color = myEventsList.type
          ? EVENT_STYLE[myEventsList.type].color
          : "#fff";
        const border = myEventsList.type
          ? EVENT_STYLE[myEventsList.type].border
          : "1px solid #fff";
        return { style: { backgroundColor, color, border } };
      },
    }),
    [i18n.language]
  );

  const changeDate = (dates, type) => {
    let init = "";
    let end = "";
    if (type) {
      switch (type) {
        case "day":
          init = new Date(dates[0]);
          end = new Date(dates[0]);
          break;
        case "week":
          init = new Date(dates[0]);
          end = new Date(dates[6]);
          break;
        case "month":
          init = new Date(dates.start);
          end = new Date(dates.end);
          break;
        default:
          init = new Date();
          end = new Date();
          break;
      }
    } else {
      if (dates.start) {
        init = new Date(dates.start);
        end = new Date(dates.end);
      } else {
        if (dates.length > 1) {
          init = new Date(dates[0]);
          end = new Date(dates[6]);
        } else {
          init = new Date(dates[0]);
          end = new Date(dates[0]);
        }
      }
    }
    loadData(init, end);
  };

  const handleSelectEvent = (data) => {
    let cause;
    let machine;
    if (data.cause) {
      switch (data.type) {
        case CATEGORY_CLEAN.code:
          cause = CLEAN_CAUSES.find((c) => c.code === data.cause);
          break;
        case CATEGORY_CHANGES.code:
          cause = CHANGES_CAUSES.find((c) => c.code === data.cause);
          break;
        case CATEGORY_OTHERS.code:
          cause = OTHER_CAUSES.find((c) => c.code === data.cause);
          break;
        default:
          break;
      }
    }
    if (data.machine) {
      machine = MACHINES.find((c) => c.code === data.machine);
    }
    if (data.type !== 100 && data.type !== CATEGORY_HOLIDAY.code) {
      dispatch(
        show("ModalForm", {
          body: (
            <>
              <div className="row mb-3">
                <div className="col-12 text-end">
                  <ButtonClose
                    type="button"
                    onClick={() => handlerClosePopup("ModalForm")}
                  >
                    <i className="fa-solid fa-xmark"></i>
                  </ButtonClose>
                </div>
              </div>
              {cause && (
                <div className="row ms-4">
                  <div className="col-12 d-flex">
                    <div>{`${t("forms.stopHistory.labels.cause")}: `}</div>
                    <Value>
                      <b>{`${t(cause.label)}`}</b>
                    </Value>
                  </div>
                </div>
              )}
              {machine && (
                <div className="row ms-4">
                  <div className="col-12 d-flex">
                    <div>{`${t("forms.stopHistory.labels.machine")}: `}</div>
                    <Value>
                      <b>{`${t(machine.label)}`}</b>
                    </Value>
                  </div>
                </div>
              )}
              <div className="row ms-4 mt-3">
                <TitleComment className="col-12">
                  {t("forms.stopHistory.commentStop")}
                </TitleComment>
              </div>
              <div className="row ms-4">
                <div className="col-12">
                  <p>
                    <b>{data.commentsIni || t("noComment")}</b>
                  </p>
                </div>
              </div>
              <div className="row mt-5 ms-4">
                <TitleComment className="col-12">
                  {t("forms.stopHistory.commentInit")}
                </TitleComment>
              </div>
              <div className="row mb-5 ms-4">
                <div className="col-12">
                  <p>
                    <b>{data.commentsEnd || t("noComment")}</b>
                  </p>
                </div>
              </div>
            </>
          ),
        })
      );
    }
  };

  const handlerOnChangeEventType = (value) => {
    setEventType(value);
  };

  return (
    <>
      {showSelectType && (
        <div className="row">
          <div className="col-12 text-end">
            <RadioGroup
              name="eventType"
              selectedValue={eventType}
              onChange={handlerOnChangeEventType}
            >
              <Radio value="stops" className="form-check-input" />
              <RadioLabel className="form-check-label">
                {t("forms.turnHistory.onlyStops")}
              </RadioLabel>
              <Radio value="turns" className="form-check-input" />
              <RadioLabel className="form-check-label">
                {t("forms.turnHistory.onlyTuns")}
              </RadioLabel>
              <Radio value="all" className="form-check-input" />
              <RadioLabel className="form-check-label">
                {t("forms.turnHistory.stopsTurns")}
              </RadioLabel>
            </RadioGroup>
          </div>
        </div>
      )}

      <div className="row mt-3">
        <div className="col-12">
          <CalendarContainer>
            <Calendar
              onSelectEvent={handleSelectEvent}
              culture={i18n.language}
              messages={messages}
              backgroundEvents={turns || []}
              dayLayoutAlgorithm="no-overlap"
              defaultDate={defaultDate}
              defaultView={defaultView || Views.MONTH}
              events={events || []}
              localizer={localizer}
              max={max}
              showMultiDayTimes
              step={30}
              views={views}
              eventPropGetter={eventStyleGetter}
              onRangeChange={changeDate}
            />
          </CalendarContainer>
        </div>
      </div>
    </>
  );
};

TurnHistoryListContainer.propTypes = {
  localizer: PropTypes.instanceOf(DateLocalizer),
};

export default TurnHistoryListContainer;
