import React, { useState, useContext } from "react";
import List from "../moveFlowsList";
import Filter from "../moveFlowsFilter";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { useTranslation } from "react-i18next";
import PATHS from "../../../../constants/paths";
import Breadcrumb from "../../../utils/breadCrumbs";
import * as XLSX from "xlsx";
import * as MoveFlowServices from "../../../../services/MoveFlowServices";
import moment from "moment-timezone";
import { ALMOND_TYPE } from "../../../../constants/index";
import { Can, AbilityContext } from "../../../security/permissions/Can";
import { USER_PERMISSIONS } from "../../../security/permissions/rolesPermission";
import { getDateTimeZone } from "../../../../utils/formats";

const queryClient = new QueryClient();

const MoveFlowsFilterListContainer = (props) => {
  const { t } = useTranslation();
  const context = useContext(AbilityContext);

  const breads = [
    { label: t("home"), path: PATHS.HOME },
    { label: t("forms.moveFlow.title") },
  ];
  const [filters, setFilters] = useState(undefined);

  const handlerCleanFilter = async (resetForm) => {
    resetForm();
    setFilters(undefined);
  };

  const handlerOnSubmitFilter = (values) => {
    let newFilter = {};
    const valuesFilters = Object.entries(values);
    valuesFilters.forEach((value) => {
      if (value[1] !== undefined && value[1].toString().length > 0) {
        newFilter[value[0]] = value[1];
      }
    });

    setFilters(newFilter);
  };

  const exportToExcel = async () => {
    const filter = {
      filters,
      sort: "-date",
    };
    MoveFlowServices.listToExport(filter).then((result) => {
      const data = result.map((move) => {
        let type;
        if (move.type) {
          type = ALMOND_TYPE.find((st) => st.code === move.type);
        }
        return {
          A: getDateTimeZone(move.date),
          B: move.harvestYear || "",
          C: move.lote,
          D: t(move.point),
          E: move.weight,
          F: move.item,
          G: type ? t(type.label) : "-",
          H: move.variety.replace("PODRIDO", "").trim(),
          I: move.calibre === "CAS" || move.type === 4 ? "-" : move.calibre,
          J: move.partidora ? "PARTIENDO" : "REPROCESANDO",
          K: move.user,
        };
      });
      data.unshift({
        A: "Fecha",
        B: "Campaña",
        C: "Lote",
        D: "Punto E/S",
        E: "Peso (Kg)",
        F: "Artículo",
        G: "Tipo",
        H: "Variedad",
        I: "Calibre",
        J: "¿Partiendo?",
        K: "Operario",
      });

      const filename = `Production_export_${moment(new Date()).format(
        "DDMMyyyyHHmm"
      )}`;
      const sheetName = "Listado de producción";
      var ws = XLSX.utils.json_to_sheet(data, {
        header: ["A", "B", "C", "D", "E", "F", "G", "H", "I", "J", "k"],
        skipHeader: true,
        UTC: true,
        dateNF: "dd-mm-yyyy hh:mm;@",
      });
      // dateNF:'mm/dd/yyyy;@',cellDates:true, raw: true}
      const wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, sheetName);
      XLSX.writeFile(wb, filename + ".xlsx");
    });
  };

  return (
    <>
      <div className="row justify-content-center mt-5">
        <div className="col-12 col-md-11 marg-btm-1rem marg-top-40px">
          <h3 className="title">{t("forms.moveFlow.title")}</h3>
        </div>
      </div>
      <Breadcrumb items={breads} />
      <Can do={USER_PERMISSIONS.FILTER_MOVE}>
        <div className="row justify-content-center">
          <div className="col-12 col-md-11">
            <Filter
              onSubmit={handlerOnSubmitFilter}
              onClean={handlerCleanFilter}
            />
          </div>
        </div>
      </Can>
      <div className="row justify-content-center">
        <div className="col-12 col-md-11">
          <QueryClientProvider client={queryClient}>
            <List className="marg-top-1rem" filters={filters} />
          </QueryClientProvider>
        </div>
      </div>
      <Can do={USER_PERMISSIONS.EXPORT_MOVE}>
        <div className="row justify-content-center mt-4 mb-5">
          <div className="col-12 col-md-11 text-center">
            <button
              type="button"
              className="btn btn-lg btn-secondary"
              onClick={() => exportToExcel()}
            >
              <i className="fa-solid fa-file-excel icon-button"></i>{" "}
              {t("exportFileExcel")}
            </button>
          </div>
        </div>
      </Can>
    </>
  );
};

MoveFlowsFilterListContainer.propTypes = {};

export default MoveFlowsFilterListContainer;
