import React, { useState } from "react";
import * as MoveFlowServices from "../../../../services/MoveFlowServices";
import PaginateList from "../../../utils/PaginateList";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import {
  checkBootstrapSizeBreak,
  SIZES,
} from "../../../../utils/device/checkDevice";
import { converToTime } from "../../../../utils/formats/index";
import { ALMOND_TYPE } from "../../../../constants/index";
import { getDateFormatTimeZone } from "../../../../utils/formats";

const MoveFlowsListContainer = ({ filters, sortBy }) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const deviceSize = checkBootstrapSizeBreak();
  const [forceRefresh, setForceRefresh] = useState(false);

  const columns = [
    {
      header: t("forms.moveFlow.table.head.date"),
      accessorKey: "date",
      cell: (info) => {
        if (info.row.original.type === 1) {
          return (
            <span className="consumo2">
              {getDateFormatTimeZone(info.getValue(), "DD-MM-yyyy HH:mm")}
            </span>
          );
        } else {
          return (
            <span className="produccion2">
              {getDateFormatTimeZone(info.getValue(), "DD-MM-yyyy HH:mm")}
            </span>
          );
        }
      },
      size: 200,
    },
    // {
    //   header: "Tipo",
    //   accessorKey: "type",
    //   cell: (info) => info.getValue(),
    //   size: 200,
    // },
    {
      header: t("forms.moveFlow.table.head.batch"),
      accessorKey: "batch",
      cell: (info) => {
        if (info.row.original.type === 1) {
          return <span className="consumo2">{info.getValue()}</span>;
        } else {
          return <span className="produccion2">{info.getValue()}</span>;
        }
      },
    },
    {
      header: t("forms.moveFlow.table.head.point"),
      accessorKey: "locationUuid",
      cell: (info) => {
        let infoData;
        if (info.row.original.location) {
          infoData = `${t(info.row.original.location.name)}`;
        } else {
          infoData = info.getValue();
        }

        if (info.row.original.type === 1) {
          return <span className="consumo2">{infoData}</span>;
        } else {
          return <span className="produccion2">{infoData}</span>;
        }
      },
    },
    {
      header: t("forms.moveFlow.table.head.weight"),
      accessorKey: "totalWeight",
      cell: (info) => {
        if (info.row.original.type === 1) {
          return (
            <span className="consumo2">{`${info.row.original.totalWeight}`}</span>
          );
        } else {
          return (
            <span className="produccion2">{`${info.row.original.totalWeight}`}</span>
          );
        }
      },
    },
    {
      header: t("forms.moveFlow.table.head.item"),
      accessorKey: "itemCode",
      cell: (info) => {
        let infoData;
        if (info.row.original.item) {
          infoData = `${info.row.original.item.code}`;
        } else {
          infoData = "-";
        }

        if (info.row.original.type === 1) {
          return <span className="consumo2">{infoData}</span>;
        } else {
          return <span className="produccion2">{infoData}</span>;
        }
      },
    },
  ];

  const listData = async (filter) => {
    if (sortBy) {
      filter.sort = sortBy;
    } else {
      filter.sort = "-date";
    }
    const result = await MoveFlowServices.listFilter(filter);
    return result;
  };

  const hide = {};
  let resize = null;

  if (deviceSize < SIZES.md) {
    hide.itemCode = false;
    resize = false;
  } else if (deviceSize === SIZES.md) {
    hide.itemCode = false;
  }

  return (
    <>
      <PaginateList
        refresh={forceRefresh}
        filters={filters}
        columns={columns}
        toList={listData}
        hideColum={hide}
        resize={resize}
        resetRefresh={() => setForceRefresh(false)}
      ></PaginateList>
    </>
  );
};

export default MoveFlowsListContainer;
