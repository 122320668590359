import React, { useState, useEffect } from "react";
import List from "../turnHistoryList";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { useTranslation } from "react-i18next";
import { useTurn } from "../hook/useTurn";
import { useDispatch } from "react-redux";
import { show, hide } from "redux-modal";
import { ButtonClose } from "./styled";
import TurnForm from "../turnForm";
import * as XLSX from "xlsx";
import moment from "moment-timezone";
import * as TurnServices from "../../../../services/TurnServices";
import { getDateTimeZone } from "../../../../utils/formats";

const queryClient = new QueryClient();

const TurnHistoryViewListContainer = (props) => {
  const { t } = useTranslation();

  const [refresh, setRefresh] = useState(false);
  const { lastTurn, registerTurn } = useTurn();
  const dispatch = useDispatch();
  const [filters, setFilters] = useState(undefined);

  // const handlerCleanFilter = async (resetForm) => {
  //   resetForm();
  //   setFilters(undefined);
  // };

  // const handlerOnSubmitFilter = (values) => {
  //   let newFilter = {};
  //   const valuesFilters = Object.entries(values);
  //   valuesFilters.forEach((value) => {
  //     if (value[1] !== undefined && value[1].toString().length > 0) {
  //       newFilter[value[0]] = value[1];
  //     }
  //   });

  //   setFilters(newFilter);
  // };

  useEffect(() => {
    setRefresh(false);
  }, [refresh]);

  const handlerClosePopup = (popupName) => {
    dispatch(hide(popupName));
  };

  const exportToExcel = async () => {
    const filter = {
      filters,
      sort: "initDate",
    };
    TurnServices.listToExport(filter).then((result) => {
      const data = result.map((turn) => {
        return {
          A: turn.dataUser ? turn.dataUser.email : "-",
          B: turn.initDate ? getDateTimeZone(turn.initDate) : "-",
          C: turn.endDate ? getDateTimeZone(turn.endDate) : "-",
          D: turn.realInitDate ? getDateTimeZone(turn.realInitDate) : "-",
          E: turn.realEndDate ? getDateTimeZone(turn.realEndDate) : "-",
          F: turn.initWeight || 0,
          G: turn.endWeight || 0,
          H: turn.comments || "-",
        };
      });
      data.unshift({
        A: "Usuario",
        B: "Fecha Inicio",
        C: "Fecha Fin",
        D: "Fecha Real de Inicio",
        E: "Fecha Real de Fin",
        F: "Peso de inicio de turno",
        G: "Peso de fin de turno",
        H: "Comentarios",
      });

      const filename = `Turnos_export_${moment(new Date()).format(
        "DDMMyyyyHHmm"
      )}`;
      const sheetName = "Listado de turnos";
      var ws = XLSX.utils.json_to_sheet(data, {
        header: ["A", "B", "C", "D", "E", "F", "G", "H"],
        skipHeader: true,
        UTC: true,
        dateNF: "dd-mm-yyyy hh:mm;@",
      });
      // dateNF:'mm/dd/yyyy;@',cellDates:true, raw: true}
      const wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, sheetName);
      XLSX.writeFile(wb, filename + ".xlsx");
    });
  };

  const addTurn = async () => {
    dispatch(
      show("ModalForm", {
        body: (
          <>
            <div className="row mb-3">
              <div className="col-12 text-end">
                <ButtonClose
                  type="button"
                  onClick={() => handlerClosePopup("ModalForm")}
                >
                  <i className="fa-solid fa-xmark"></i>
                </ButtonClose>
              </div>
            </div>
            <div className="row">
              <div className="col-12">
                <TurnForm
                  onCancel={() => handlerClosePopup("ModalForm")}
                  onSuccess={() => {
                    handlerClosePopup("ModalForm");
                    setRefresh(true);
                  }}
                ></TurnForm>
              </div>
            </div>
          </>
        ),
      })
    );
  };

  return (
    <>
      <div className="row justify-content-center no-margin">
        <div className="col-12 col-md-11 mt-3 text-end">
          <button
            type="button"
            className="btn btn-md btn-secondary"
            onClick={() => exportToExcel()}
          >
            <i className="fa-solid fa-file-excel icon-button"></i>{" "}
            {t("exportFileExcel")}
          </button>
        </div>
      </div>
      <div className="row justify-content-center">
        <div className="col-12 col-md-11">
          <QueryClientProvider client={queryClient}>
            <List
              className="marg-top-1rem"
              filters={filters}
              refresh={refresh}
            />
          </QueryClientProvider>
        </div>
      </div>
      <div className="row justify-content-center mt-5">
        <div className="col-12 col-md-11 text-center">
          <button
            className="btn btn-primary btn-md"
            type="button"
            onClick={() => addTurn()}
          >
            <span>{t("forms.turnHistory.buttons.addTurn")}</span>
          </button>
        </div>
      </div>
    </>
  );
};

TurnHistoryViewListContainer.propTypes = {};

export default TurnHistoryViewListContainer;
