import React, { useState, useEffect } from "react";
import * as TurnServices from "../../../../services/TurnServices";
import PaginateList from "../../../utils/PaginateList";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { ButtonAction, Icon, ButtonClose } from "./turnHistoryList.styled";
import showDialog from "../../../utils/ShowDialog/ShowDialog";
import { useDispatch } from "react-redux";
import { show, hide } from "redux-modal";
import TurnForm from "../turnForm";
import { TURN_STATUS } from "../../../../constants/index";
import { getDateFormatTimeZone } from "../../../../utils/formats";

const TurnHistoryListContainer = ({ filters, sortBy, errors, refresh }) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [forceRefresh, setForceRefresh] = useState(false);
  const dispatch = useDispatch();

  const handlerClosePopup = (popupName) => {
    dispatch(hide(popupName));
  };

  useEffect(() => {
    if (refresh) {
      setForceRefresh(true);
    }
  }, [refresh]);

  const columns = [
    {
      header: t("forms.turnHistory.table.head.initDate"),
      accessorKey: "initDate",
      cell: (info) =>
        getDateFormatTimeZone(info.getValue(), "DD-MM-yyyy HH:mm"),
    },
    {
      header: t("forms.turnHistory.table.head.endDate"),
      accessorKey: "endDate",
      cell: (info) =>
        getDateFormatTimeZone(info.getValue(), "DD-MM-yyyy HH:mm"),
    },
    {
      header: t("forms.turnHistory.table.head.user"),
      accessorKey: "dataUser.email",
      cell: (info) => info.getValue(),
    },
    {
      header: t("forms.turnHistory.table.head.status"),
      accessorKey: "status",
      cell: (info) => {
        const type = TURN_STATUS.find(
          (st) => st.code === info.row.original.status
        );
        return type ? t(type.label) : info.getValue();
      },
    },
    {
      header: "",
      accessorKey: "ac",
      cell: (info) => renderAction(info.row.original),
    },
  ];

  const editTurn = async (uuid) => {
    dispatch(
      show("ModalForm", {
        body: (
          <>
            <div className="row mb-3">
              <div className="col-12 text-end">
                <ButtonClose
                  type="button"
                  onClick={() => handlerClosePopup("ModalForm")}
                >
                  <i className="fa-solid fa-xmark"></i>
                </ButtonClose>
              </div>
            </div>
            <div className="row">
              <div className="col-12">
                <TurnForm
                  uuid={uuid}
                  onCancel={() => handlerClosePopup("ModalForm")}
                  onSuccess={() => {
                    handlerClosePopup("ModalForm");
                    setForceRefresh(true);
                  }}
                ></TurnForm>
              </div>
            </div>
          </>
        ),
      })
    );
  };

  const renderAction = (data) => {
    return (
      <>
        <div className="dropdown text-center">
          <ButtonAction
            onClick={() => editTurn(data.uuid)}
            title={t("forms.order.labels.edit")}
          >
            <Icon className="fa-regular fa-pen-to-square"></Icon>
          </ButtonAction>
          <ButtonAction
            onClick={() => showDialogOnDelete(data.uuid)}
            title={t("forms.turnHistory.labels.delete")}
          >
            <Icon className="fa-solid fa-trash-can"></Icon>
          </ButtonAction>
        </div>
      </>
    );
  };

  const showDialogOnDelete = (uuid) => {
    const okAction = () => {
      TurnServices.deleteTurn(uuid).then((result) => {
        setForceRefresh(true);
      });
    };

    const cancelAction = () => {};

    showDialog(
      t("forms.turnHistory.dialog.delete.title"),
      t("forms.turnHistory.dialog.delete.text"),
      "question",
      true,
      t("forms.turnHistory.dialog.delete.buttonOk"),
      false,
      okAction,
      t("forms.turnHistory.dialog.delete.buttonNo"),
      cancelAction
    );
  };

  const listData = async (filter) => {
    if (sortBy) {
      filter.sort = sortBy;
    } else {
      filter.sort = "-date";
    }
    const result = await TurnServices.listFilter(filter);
    return result;
  };

  return (
    <>
      <PaginateList
        refresh={forceRefresh}
        filters={filters}
        columns={columns}
        toList={listData}
        resetRefresh={() => setForceRefresh(false)}
      ></PaginateList>
    </>
  );
};

export default TurnHistoryListContainer;
